import { gql, useQuery } from '@apollo/client';

import {ContactFields} from './fragments';

export const CreateContact = gql`
  ${ContactFields}

  mutation CreateContact($data: ContactCreateInput!) {
    createContact(data: $data) {
      ...contactFields
    }
  }
`;