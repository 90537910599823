import React, { Fragment } from "react";
import Service from "../../elements/service/ServiceList";
import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';

import { Query } from '../../graphql';

const useStyles = makeStyles({
  root: {
    display: 'flex',

  },
});

function ServiceList() {
  const classes = useStyles();

  const { loading, error, data } = useQuery(Query.GetServiceSection);

  return (
    <Fragment>
      <div className="service-area pt--120 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <span className="subtitle">{data?.section?.subtitle}</span>
                <h2 className="title">{data?.section?.title}</h2>
                <p className="description">{data?.section?.description.split("\\n\\n").map(line => (
                  <React.Fragment>{line}<br /></React.Fragment>
                ))}</p>
              </div>
            </div>
          </div>
          <div className="row service-main-wrapper">
            {data?.section.children.map((service, i) => (
              <Service key={i} item={i} service={service} column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ServiceList;