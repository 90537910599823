import React, { Fragment, useEffect } from "react";
import Slider from "react-slick";

import { slickDot } from "../../page-demo/script";
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Query } from '../../graphql';


function BlogList() {
  const { loading, error, data } = useQuery(Query.GetPosts);

  useEffect(() => {
    console.log("DATA = ", data)
  }, [data])

  return (
    <Fragment>
      <div className="rn-blog-area pt--120 pb--140 bg_color--5">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Latest articles</span>
                {/*<h2 className="title">Blog Posts</h2>*/}
                {/*<p className="description">There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>*/}
              </div>
            </div>
          </div>
          <div className="row mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
            <div className="col-lg-12">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: '460px' }}>
                  {data?.posts.map((post, i) => (
                    <div className="single_im_portfolio" key={i}>
                      <div className="im_portfolio">
                        <div className="thumbnail_inner">
                          <div className="thumbnail">
                            <img src={post.image.url} alt="React Creative Agency" />
                          </div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            <div className="portfolio_heading">
                              <div className="category_list">
                                <span >{post.category}</span>
                              </div>
                              <h4 className="title"><span >{post.title}</span></h4>
                            </div>
                            <div className="portfolio_hover">
                              <p>{post.description}
                                <Link to={`/blog-details/${post.slug}`}>Read More</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BlogList;