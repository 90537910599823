import React, { Component, Fragment } from "react";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";

import SlideList from "../views/SlideList";
import About from "../views/About";
import ServiceList from "../views/ServiceList";
import KpiList from "../views/KpiList";
import Portfolio from "../views/Portfolio";

class Home extends Component {
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Digital Agency" />

                {/* Start Header Area  */}
                <Header logo="light" color="color-white" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <SlideList />
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <ServiceList />
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <Portfolio />
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <KpiList />
                {/* End CounterUp Area */}

                {/* Start About Area */}
                <About />
                {/* End About Area */}

                {/* Start Blog Area */}
                {/*<Portfolio />*/}
                {/* End Blog Area */}

                {/* Start Brand Area */}
                {/* End Brand Area */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default Home;