import React, { Fragment } from "react";
import Markdown from 'react-markdown';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import TabOne from "../../elements/tab/TabOne";
import { useQuery } from '@apollo/client';
import { Query } from '../../graphql';

const useStyles = makeStyles({
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "#FD4766"
  },
});

function About() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(Query.GetAboutSection);
  console.log("ABOUT", error);

  let description = data?.section.content;
  let tabs = data?.section?.children || [];

  return (
    <Fragment>
      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-5">
                <div className="thumbnail">
                  {loading &&
                    <Skeleton variant="rect" width={400} height={600} animation="wave" />
                  }{!loading &&
                    <img className="w-100" src={data?.section.image.url} alt="About Images" />
                  }
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <span className="subtitle">{data?.section.subtitle}</span>
                    <h2 className="title">{data?.section.title}</h2>
                    {!loading &&
                      description.split("\\n\\n").map(markdown => {
                        return (
                          <span style={{ display: "block", marginBottom: "30px" }}><Markdown>{markdown}</Markdown></span>
                        )
                      })
                    }
                  </div>
                  {loading &&
                    <span className={classes.loader}>
                      <CircularProgress color={"inherit"} />
                    </span>
                  }{!loading &&
                    <div className="row mt--30">
                      <TabOne tabStyle="tab-style--1" tabs={(tabs)} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default About;