import React, { useState } from "react";
import Paper from '@material-ui/core/Paper';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { FiHeart, FiClock, FiCheckCircle, FiAward } from "react-icons/fi";
import { FaRegSmileBeam } from "react-icons/fa";
import { SiCoffeescript, SiBuymeacoffee } from "react-icons/si";
import { GiCoffeeMug, GiCoffeeCup } from "react-icons/gi";
import { MdTimer } from "react-icons/md";
import { AiOutlineClockCircle, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsFolderCheck, BsCheckCircle } from "react-icons/bs";


const Icons = {
  FiHeart: <FiHeart />,
  FiClock: <FiClock />,
  FiCheckCircle: <FiCheckCircle />,
  FiAward: <FiAward />,
  FaRegSmileBeam: <FaRegSmileBeam />,
  SiCoffeescript: <SiCoffeescript />,
  SiBuymeacoffee: <SiBuymeacoffee />,
  GiCoffeeMug: <GiCoffeeMug />,
  GiCoffeeCup: <GiCoffeeCup />,
  MdTimer: <MdTimer />,
  AiOutlineClockCircle: <AiOutlineClockCircle />,
  AiOutlineFundProjectionScreen: <AiOutlineFundProjectionScreen />,
  BsFolderCheck: <BsFolderCheck />,
  BsCheckCircle: <BsCheckCircle />,
}


function Kpi(props) {
  const { kpi } = props;
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true)
    }
  }

  return (
    <div className="im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12" key={props.id}>
      <Paper elevation={3} className="im_counterup">
        <div className="inner">
          <div className="icon">
            {Icons[kpi.icon]}
          </div>
          <h2 className="counter">
            <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
              <CountUp end={didViewCountUp ? kpi.total : 0} />
            </VisibilitySensor>
            {props.char}
          </h2>
          <p className="description">{kpi.name}</p>
        </div>
      </Paper>
    </div>
  )
}
export default Kpi;