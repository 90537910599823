import { gql, useQuery } from '@apollo/client';
import {ServiceFields, KpiFields, SliderFields, AboutFields} from './fragments';


export const GetServices = gql`
  ${ServiceFields}

  query GetServices {
    services {
      ...serviceFields
    }
  }
`;

export const GetService = gql`
  ${ServiceFields}

  query GetService($slug: String) {
    service(where: {slug: $slug}) {
      ...serviceFields
    }
  }
`;

export const GetAbout = gql`
  ${AboutFields}

  query GetAbout {
    about(where: {slug: "aboutus"}) {
      ...aboutFields
      tabs {
      ... on Tab {
        id
        content
        title
      }
    }
    }
  }
`;

export const GetPortfolio = gql`
  query GetPortfolio {
    portfolio(where: {slug: "portfolio"}) {
      projects {
        ... on Project {
          id
          category
          description
          image
          title
        }
      }
      description
      subtitle
      title
    }
  }
`;


export const GetPost = gql`
  query GetPost($slug: String) {
    post(where: {slug: $slug}) {
      id
      title
      slug
      description
      content
      image {
        url
      }
      createdAt
      createdBy {
        id
        name
      }
    }
  }
`;

export const GetPosts = gql`

  query GetPosts {
    posts {
      id
      title
      slug
      description
      image {
        url
      }
    }
  }
`;

export const GetPortfolioSection = gql`

  query GetPortfolioSection {
    section(where: {slug: "portfolio"}) {
      children {
        ... on Project {
          id
          category
          description
          image {
            url
          }
          title
        }
      }
      description
      subtitle
      title
    }
  }
`;

export const GetServiceSection = gql`
  ${ServiceFields}

  query GetServiceSection {
    section(where: {slug: "services"}) {
      children {
        ... on Service {
          ...serviceFields
        }
      }
      description
      subtitle
      title
    }
  }
`;

export const GetAboutSection = gql`
    ${AboutFields}

  query GetAboutSection {
    section(where: {slug: "aboutus"}) {
      ...aboutFields
      image {
        url
      }
      children {
        ... on Tab {
          id
          content
          title
        }
      }
    }
  }
`;

export const GetSliders = gql`
  ${SliderFields}

  query GetSliders {
    sliders(orderBy: position_ASC) {
      ...sliderFields
      banner {
        url
      }
      service {
        slug
      }
    }
  }
`;

export const GetKpi = gql`
    query ExampleQuery($id: ID!, $kpiStage: Stage!) {
      kpi(where: {id: $id}, stage: $kpiStage) {
        id
        name
        icon
        total
      }
    }
`;

export const GetKpis = gql`
  ${KpiFields}

  query GetKpis {
    one: kpi(where: {id: "cksmm8nx43dvs0c85l0w6h25c"}) {
      ...kpiFields
    }
    two: kpi(where: {id: "cksmnxpg83g0b0b867gfqcbif"}) {
      ...kpiFields
    }
    three: kpi(where: {id: "cksmmald43dyr0c8585dm5bm5"}) {
      ...kpiFields
    }
    four: kpi(where: {id: "cksmm9ucw3dzm0b86e4idkphc"}) {
      ...kpiFields
    }
  }
`;

