import React, { Fragment } from "react";
import Slider from "react-slick";

import { slickDot } from "../../page-demo/script";
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Query } from '../../graphql';


function Portfolio() {
  const { loading, error, data } = useQuery(Query.GetPortfolioSection);

  console.log("DATA", data)

  return (
    <Fragment>
      {/* Start Portfolio Area */}
      <div className="portfolio-area pt--120 pb--140 bg_color--5">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">{data?.section.subtitle}</span>
                  <h2 className="title">{data?.section.title}</h2>
                  <p className="description">{data?.section.description}</p>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-lg-12">
                <div className="slick-space-gutter--15 slickdot--20">
                  <Slider {...slickDot}>
                    {data?.section.children?.map((project, index) => (
                      <div className="single_im_portfolio" key={index}>
                        <div className="im_portfolio">
                          <div className="thumbnail_inner">
                            <div className="thumbnail">
                              <img src={project.image.url} alt="React Creative Agency" />
                            </div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <div className="portfolio_heading">
                                <div className="category_list">
                                  <span >{project.category}</span>
                                </div>
                                <h4 className="title"><span >{project.title}</span></h4>
                              </div>
                              <div className="portfolio_hover">
                                <p>{project.description}</p>
                              </div>
                            </div>
                          </div>
                          <span className="transparent_link" ></span>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

    </Fragment>
  )
}

export default Portfolio;