import React, { Fragment } from "react";

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';


import { Query } from '../../graphql';
import Kpi from '../../elements/Kpi';

const useStyles = makeStyles({
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "#FD4766"
  },
});

function KpiList() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(Query.GetKpis);
  return (
    <Fragment>
      <div className="rn-counterup-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Our Stats</span>
                <h2 className="title">Aelo in Numbers</h2>
                {/*<p className="description">and counting...</p>*/}
              </div>
            </div>
          </div>
          <div className="row mt--30">
            {loading &&
              <span className={classes.loader}>
                <CircularProgress color={"inherit"} />
              </span>
            }{!loading &&
              < React.Fragment >
                <Kpi char={"%"} kpi={data?.one} />
                <Kpi char={"+"} kpi={data?.two} />
                <Kpi char={"+"} kpi={data?.three} />
                <Kpi char={"+"} kpi={data?.four} />
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </Fragment >
  )
}

export default KpiList;