import React, { useState } from "react";
import ReactGA from 'react-ga';

import { useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { Mutations } from '../../graphql';
import { useContactDialog } from "../../views/ContactDialog";

const useStyles = makeStyles({
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "#FD4766"
  },
});

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  subject: yup.string(),
  message: yup.string(),
});

function ContactOne() {
  const classes = useStyles();
  const { setOpen } = useContactDialog();
  const [createContact, { loading, error, data }] = useMutation(Mutations.CreateContact, {
    onCompleted: () => setOpen(false)
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    console.log("Submitted");

    ReactGA.event({
      category: 'User',
      action: 'Sent A Message'
    });

    console.log("Mutating");
    createContact({
      variables: {
        data: data,
      }
    });
    console.log("Mutated");
  }

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="form-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="caption" display="block" gutterBottom>
              {errors.name?.message}
            </Typography>
            <label htmlFor="name">
              <input
                id={"item01"}
                {...register("name")}
                placeholder="Your Name *"
              />
            </label>

            <Typography variant="caption" display="block" gutterBottom>
              {errors.email?.message}
            </Typography>
            <label htmlFor="item02">
              <input
                id="item02"
                {...register("email")}
                placeholder="Your email *"
              />
            </label>

            <Typography variant="caption" display="block" gutterBottom>
              {errors.subject?.message}
            </Typography>
            <label htmlFor="item03">
              <input
                id="item03"
                {...register("subject")}
                placeholder="Write a Subject"
              />
            </label>

            <Typography variant="caption" display="block" gutterBottom>
              {errors.message?.message}
            </Typography>
            <label htmlFor="item04">
              <textarea
                id="item04"
                name="message"
                {...register("message")}
                placeholder="Your Message"
              />
            </label>
            {loading &&
              <span className={classes.loader}>
                <CircularProgress color={"inherit"} />
              </span>
            }{!loading &&
              <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            }
          </form>
        </div>
      </div>
    </div>
  )
}
export default ContactOne;