import { gql } from '@apollo/client';

export const ServiceFields = gql`
  fragment serviceFields on Service {
    id
    name
    slug
    description
    icon
    banner {
      url
    }
    subtitle
    profile {
      url
    }
    content
  }
`;

export const PostFields = gql`
  fragment postFields on Post {
    id
    title
    slug
    description
    image {
      url
    }
    content
  }
`;

export const AboutFields = gql`
  fragment aboutFields on Section {
    content
    title
    subtitle
  }
`;

export const KpiFields = gql`
  fragment kpiFields on Kpi {
    id
    name
    icon
    total
  }
`;

export const SliderFields = gql`
  fragment sliderFields on Slider {
    category
    title
    description
  }
`;

export const ContactFields = gql`
  fragment contactFields on Contact {
    name
    email
    subject
    message
  }
`;

