import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { useContactDialog } from "../../views/ContactDialog";

function Header(props) {
    const contactDialog = useContactDialog();

    const { logo, color = 'default-color' } = props;
    let logoUrl;
    if (logo === 'light') {
        logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    } else if (logo === 'dark') {
        logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
    } else if (logo === 'symbol-dark') {
        logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
    } else if (logo === 'symbol-light') {
        logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    } else {
        logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    }

    return (
        <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <a href="/">
                            {logoUrl}
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <div className="header-btn">
                        <a className="btn-default btn-border btn-opacity" target="_blank" onClick={() => contactDialog.setOpen(true)}>
                            <span>Get in touch</span>
                        </a>
                    </div>
                </div>
            </div>

        </header>
    )
}
export default Header;