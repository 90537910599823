import React, { useState, useEffect } from "react";
import Markdown from 'react-markdown';
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {
  useParams
} from "react-router-dom";

import { useQuery } from '@apollo/client';

import { useContactDialog } from "../views/ContactDialog";

import { Query } from '../graphql';


function ServiceDetails(props) {
  let { slug } = useParams();
  const { open, setOpen } = useContactDialog();
  const { loading, error, data } = useQuery(Query.GetService, {
    variables: {
      slug
    }
  });
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => { console.log(error) }, [error])
  useEffect(() => {
    if (data?.service?.content) {
      setParagraphs(data.service.content.split("\\n\\n"));
    }
  }, [data])

  return (
    <React.Fragment>

      {/* Start Pagehelmet  */}
      <PageHelmet pageTitle='Service Details' />
      {/* End Pagehelmet  */}

      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Breadcrump Area */}
      <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{ backgroundImage: `url(${data?.service.banner.url})` }} data-black-overlay="5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">{data?.service.name}</h2>
                <p>{data?.service.subtitle}</p>

                {/*<div className="breadcrumb-inner pt--100">
                  <ul className="page-list">
                    <li className="rn-breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                    <li className="rn-breadcrumb-item active">{data?.service.name}</li>
                  </ul>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row row--35 sercice-details-content align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details">
                        <div className="section-title">
                          {/*<span className="subtitle">Our service</span>*/}
                          {
                            paragraphs.map(markdown => {
                              return (
                                <span style={{ display: "block", marginBottom: "30px" }}><Markdown>{markdown}</Markdown></span>
                              )
                            })
                          }
                        </div>
                        <div className="slide-btn"><a className="btn-default" onClick={() => setOpen(true)}>LET'S TALK</a></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                      <div className="thumb position-relative">
                        {data?.service?.profile &&
                          < img className="w-100 h-200" src={data?.service.profile.url} alt="Service Images" />
                        }
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />

    </React.Fragment>
  )
}

export default ServiceDetails;