import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Markdown from 'react-markdown';

function TabsOne({ tabs, tabStyle }) {

    return (
        <div>
            {/* Start Tabs Area */}
            <div className="tabs-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs>
                                <TabList className={`${tabStyle}`}>
                                    {tabs?.map(tab => (
                                        <Tab key={tab.id}>{tab.title}</Tab>
                                    ))}
                                </TabList>

                                {tabs.map(tab => (
                                    <TabPanel key={tab.id}>
                                        <div className="single-tab-content">
                                            {tab?.content?.split("\\n\\n").map(paragraph => {
                                                return (
                                                    <span style={{ display: "block", marginBottom: "30px" }}><Markdown>{paragraph}</Markdown></span>
                                                );
                                            })}
                                        </div>
                                    </TabPanel>
                                ))}

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tabs Area */}
        </div>
    );
}
export default TabsOne;