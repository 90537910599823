import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

import ContactForm from "../../blocks/ContactForm";


const ContactDialogContext = React.createContext({
  setContactDialog: (open) => { },
});

export const ContactDialogConsumer = ContactDialogContext.Consumer;

export const useContactDialog = () => useContext(ContactDialogContext);

const useStyles = makeStyles({
  paper: {
    margin: "0px !important",
    paddingBottom: "8px"
  },
});

export const ContactDialogProvider = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ContactDialogContext.Provider value={{
        open: open,
        setOpen: setOpen
      }}>
        {props.children}
        <Dialog disableScrollLock classes={{ paper: classes.paper }} open={open} onClose={() => setOpen(false)}>
          <DialogTitle id="form-dialog-title">Get in Touch</DialogTitle>
          <ContactForm />
        </Dialog>
      </ContactDialogContext.Provider>
    </React.Fragment>
  )
};