import React from 'react'
import ContactOne from "../elements/contact/ContactOne";

const ContactForm = () => {
  return (
    <div style={{ width: 340, padding: 8, margin: 0 }}>
      <ContactOne contactTitle="Contact Us" />
    </div>
  )
}

export default ContactForm;