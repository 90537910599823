import React, { Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";

import { useQuery } from '@apollo/client';

import { Query } from '../../graphql';

import { useContactDialog } from "../ContactDialog";


function Slide() {
  const { open, setOpen } = useContactDialog();

  const { loading, error, data } = useQuery(Query.GetSliders);

  return (
    <Fragment>
      {/* Start Slider Area   */}
      <div className="slider-wrapper color-white">
        <div className="slider-activation slider-digital-agency">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {data?.sliders.map((value, index) => (
              <span>
                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image`} style={{ backgroundImage: `url(${value.banner.url})` }} key={index} data-black-overlay="2">
                  <div className="container" >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ''}
                          {value.title ? <h1 className="title">{value.title}</h1> : ''}
                          {value.description ? <p className="description">{value.description}</p> : ''}
                          <div className="slide-btn"><a className="btn-default" href={`/service-details/${value.service.slug}`}>TAKE A LOOK</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}
    </Fragment>
  )
}

export default Slide;