// React Required
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Home from './screens/Home';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./screens/About";
import Blog from "./screens/Blog";
import BlogDetails from "./screens/BlogDetails";
import Portfolio from "./screens/Portfolio";
import error404 from "./elements/error404";

// Views
import { ContactDialogProvider } from "./views/ContactDialog";

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
} from "@apollo/client";

import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const client = new ApolloClient({
    uri: 'https://api-ap-northeast-1.graphcms.com/v2/cksmki5270w3x01y23rha58w7/master',
    cache: new InMemoryCache()
});

ReactGA.initialize('UA-210349198-1');

function App() {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        console.log("PAGE TURNED = ", location.pathname);
        ReactGA.pageview(location.pathname);
    }, [location]);

    return (
        <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />

            {/* Element Layot */}
            <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service} />
            <Route exact path={`${process.env.PUBLIC_URL}/service-details/:slug`} component={ServiceDetails} />
            {/*<Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} /> */}
            <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
            <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
            <Route exact path={`${process.env.PUBLIC_URL}/blog-details/:slug`} component={BlogDetails} />
            <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />
            {/*<Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails} /> */}
            {/*<Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} /> */}
            {/*<Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />*/}

            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
            <Route component={error404} />

        </Switch>
    )
};

function Root() {
    return (
        <ApolloProvider client={client}>
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <ContactDialogProvider>
                        <App />
                    </ContactDialogProvider>
                </PageScrollTop>
            </BrowserRouter>
        </ApolloProvider>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();