import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BlogView from "../views/BlogList";

function Blog() {

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Blog' />

            {/* Start Header Area  */}
            <Header logo="light" color="color-white" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Blog'} />
            {/* End Breadcrump Area */}

            <BlogView />

            <Footer />

        </React.Fragment>
    )

}
export default Blog