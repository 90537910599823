import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PortfolioView from "../views/Portfolio";

function Portfolio() {

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Portfolio' />

            {/* Start Header Area  */}
            <Header logo="light" color="color-white" />
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'} />
            {/* End Breadcrump Area */}

            <PortfolioView />

            <Footer />

        </React.Fragment>
    )

}
export default Portfolio