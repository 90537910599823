import React, { useEffect, useState } from "react";
import { useParams, Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { useQuery } from '@apollo/client';
import { Query } from '../graphql';

const BlogDetails = () => {
    let { slug } = useParams();
    const { loading, error, data } = useQuery(Query.GetPost, {
        variables: {
            slug: slug
        }
    });
    //#endregion

    return (
        <React.Fragment>
            {(!data?.post && !loading) && <Redirect to="/404" />}
            <PageHelmet pageTitle={data?.post?.title || 'Blog Post'} />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{ backgroundImage: `url('${data?.post?.image?.url}')` }} data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-single-page-title text-center pt--100">
                                <h2 className="title theme-gradient">{data?.post?.title}</h2>
                                <ul className="blog-meta d-flex justify-content-center align-items-center">
                                    <li><FiClock />{(data?.post?.createdAt) && new Date(data?.post?.createdAt).toDateString()}</li>
                                    <li><FiUser />{data?.post?.createdBy?.name}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    {data?.post?.content &&
                                        <ReactMarkdown children={data?.post?.content}
                                            components={{
                                                // Map `h1` (`# heading`) to use `h2`s.
                                                h2: 'h4',
                                                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                                img: ({ node, ...props }) => <img style={{ color: 'red' }} {...props} />
                                            }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}
export default BlogDetails;