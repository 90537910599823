import React, { Component } from "react";
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import {
  MdComputer,
  MdAddShoppingCart,
  MdDevices,
  MdOutlineAppSettingsAlt,
  MdLinkedCamera, MdAppSettingsAlt, MdCampaign, MdOutlineImportantDevices
} from "react-icons/md";

import { makeStyles } from '@material-ui/core/styles';

const Icons = {
  FiCast: <FiCast />,
  FiLayers: <FiLayers />,
  FiUsers: <FiUsers />,
  FiMonitor: <FiMonitor />,
  FiUsers: <FiUsers />,
  FiMonitor: <FiMonitor />,
  MdComputer: <MdComputer />,
  MdAddShoppingCart: <MdAddShoppingCart />,
  MdDevices: <MdDevices />,
  MdLinkedCamera: <MdLinkedCamera />,
  MdAppSettingsAlt: <MdAppSettingsAlt />,
  MdCampaign: <MdCampaign />,
  MdOutlineImportantDevices: <MdOutlineImportantDevices />,
  MdOutlineAppSettingsAlt: <MdOutlineAppSettingsAlt />,
}

const useStyles = makeStyles({
  root: {
    height: "200px"
  },
});


function ServiceThree(props) {
  const classes = useStyles();
  const { column, service } = props;

  return (
    <React.Fragment>
      <div className={`${column}`}>
        <a href={`/service-details/${service.slug}`}>
          <Paper elevation={3} className="service service__style--2 text-left bg-gray">
            <div className="icon">
              {Icons[service.icon]}
            </div>
            <div className="content">
              <h3 className="title">{service.name}</h3>
              <p>{service.description}</p>
            </div>
          </Paper>
        </a>
      </div>
    </React.Fragment>
  )
}
export default ServiceThree;
